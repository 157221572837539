<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-xl font-bold mr-3">Equity Contributions</h2>
      </div>
      <div class="col-span-1">
        <search-form
          placeholder="Search user name, branch, refererence number...."
          class="mb-5"
          v-model="equityContributionResource.query"
          @submit="$refs.table.loadAjaxData()"
        />
      </div>
    </div>
    <datatable
      :url="equityContributionResource.url"
      :ajax="true"
      :ajax-pagination="true"
      :columns="equityContributionResource.columns"
      :query="equityContributionResource.query"
      :fillable="false"
      ref="table"
    />
  </div>
</template>
<script>
export default {
  props: {
    profile: {
      required: true,
      type: [Array, Object]
    }
  },
  data() {
    return {
      equityContributionResource: this.$options.resource([], {
        url: `${this.$baseurl}/admin/transactions/equity-contribution/${this.profile?.id}`,
        query: '',
        columns: [
          {
            name: 'user',
            th: 'User',
            render: ({ user }) => `${user?.name} ${user?.last_name}`
          },
          {
            name: 'branch',
            th: 'Branch',
            render: ({ branch }) => branch?.name
          },
          {
            name: 'amount_paid',
            th: 'Amount Paid',
            render: equity =>
              this.$options.filters.formatAmount(equity?.amount_paid)
          },
          {
            name: 'order_amount',
            th: 'Order Amount',
            render: equity =>
              this.$options.filters.formatAmount(equity?.order_amount)
          },
          {
            name: 'equity_amount',
            th: 'Equity Contribution Amount',
            render: equity =>
              this.$options.filters.formatAmount(equity?.equity_amount)
          },
          {
            name: 'equity_percentage',
            th: 'Equity Percentage',
            render: equity => `${equity?.equity_percentage}%`
          },
          {
            name: 'management_fee_amount',
            th: 'Management Fee',
            render: equity =>
              this.$options.filters.formatAmount(equity?.management_fee_amount)
          },
          {
            name: 'management_fee_percentage',
            th: 'Management Fee Percentage',
            render: equity => `${equity?.management_fee_percentage}%`
          },
          {
            name: 'reference',
            th: 'Reference'
          },
          {
            name: 'status',
            th: 'Status',
            render: equity => this.statusBadge(equity?.status)
          },
          {
            name: 'created_at',
            th: 'Created At',
            render: equity =>
              this.$moment(equity?.created_at).format('dddd, MMMM Do YYYY')
          }
        ]
      })
    };
  },
  methods: {
    statusBadge(status) {
      switch (status) {
        case 'success':
          return `<div class="badge badge-green-soft-outline px-7">
            Success
          </div>`;
        case 'failed':
          return `<div class="badge badge-red-soft-outline px-7">
            Failed
          </div>`;
        case 'pending':
        default:
          return `<div class="badge badge-orange-soft-outline px-7">
            Pending
          </div>`;
      }
    }
  }
};
</script>
